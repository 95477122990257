export enum Identifiers {
  HomeBrand = "home-brand",
  RecentlyView = "recently-view",
  NewCollection = "new-collection",
  TrendingBanner = "trending-banner",
  JordanMatching = "jordan-matching-widget",
  TopCategories = "top-categories",
  FeatureProduct = "featured-products",
  Recommended = "recommended",
  Footer = "footer-area",
  Newsletter = "newsletter",
  NewAbout = "about-area",
  CopyRight = "copy-right-area",
  ProductDetailBanner = "product-detail-banner",
  FAQ = "faq",

  CheckoutTerms = "checkout-terms",
  FAQProduct = "faq-product",
  VeryfiedPurchase = "veryfied-purchase",
}

export enum RelatedProductCode {
  ProductsRelatedToThisItem = "related1",
  CustomersWhoViewedThisItemAlsoViewed = "related2",
  CustomersWhoBoughtThisItemAlsoBought = "related3",
  ExclusiveItemsFromOurBrands = "related4",
  InspiredByYourBrowsingHistory = "related5",
}
export enum RelatedPosition {
  CategoruSidebarBottom = "category_sidebar_bottom",
  CartContentBottom = "cart_content_bottom",
  ProductContentBottom = "product_content_bottom",
}

export const enum SectionsKey {
  Hero = "hero",
  Brand = "brand",
  RecentlyView = "recently-view",
  BestSeller = "best-seller",
  MostPopular = "most-popular",
  Trending = "trending",
  Testimonial = "testimonial",
  ProductMatching = "product-matching",
  TopCategories = "top-categories",
  FeatureProduct = "featured-products",
  NewCollections = "new-collections",
  Recommended = "recommended",
  Newsletter = "newsletter",

  // product detail
  Breadcrumb = "breadcrumb",
  ProductDetailTwo = "product-detail-two",
  ProductDetail = "product-detail",
  ProductDetailTabs = "product-detail-tabs",
  ProductSection = "product-section",
  ProductRelated = "product-related",
  ProductRelatedCart = "product-related-cart",
  ProductDetailBanner = "product-detail-banner",
  ProductDetailMoreInfo = "product-detail-more-info",

  // checkout
  Checkout = "checkout",

  //product list
  ProductList = "product_list",

  //blog
  BlogList = "blog-list",
  BlogDetail = "blog-detail",
  RelatedBlogs = "related-blogs",

  //profile
  DeliveryAddresses = "delivery-addresses",
  ProfileInformation = "profile-information",

  //order
  OrderDetail = "order-detail",

  //category
  Category = "category",
  Collections = "collections",

  //collection
  CollectionsAbout = "collections-about",
  CollectionsRecommend = "collections-recommend",

  //Review
  Reviews = "reviews",
  TabReviews = "tab-reviews",
}

export const enum CmsBlockProductList {
  BestSeller = "best-sellers",
  Poppular = "popular",
  Trending = "trending",
  Category = "category",
  Collection = "collection",
}
export const enum MegaMenuType {
  Wrapper = "wrapper",
  CmsBlock = "cms_block",
  Category = "category",
  CustomUrl = "custom_url",
  CmsPage = "cms_page",
  Product = "product",
}

export const enum PATH {
  Blogs = "/blogs",
  BlogDetail = "/blogs/[slug]",
  Categories = "/categories",
  CategoryDetail = "/categories/[slug]",
  Collections = "/collections",
  CollectionDetail = "/collections/[slug]",
  OrderDetail = "/order/[slug]",
  ProductDetail = "/products/[slug]",
  ProductCustom = "/products/custom/[slug]",
  ProfileDeliveryAddress = "/profile/delivery-address",
  ProfileInformation = "/profile/information",
  ProfileOrderHistory = "/profile/order-history",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  CmsPage = "/[cmsPage]",
  About = "/about",
  CancelPaypal = "/cancel-paypal",
  Carts = "/carts",
  Checkout = "/checkout",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Reviews = "/reviews",
  Search = "/search",
  ThankYou = "/thank-you",
  Wishlist = "/Wishlist",
}

export enum ShortCodeFilterKey {
  category_uid = "ca",
  category_master = "ty",
  Color = "co",
  size = "si",
  frame = "fr",
  style = "st",
}
export enum LongCodeFilterKey {
  ca = "category_uid",
  ty = "category_master",
  co = "color",
  si = "size",
  fr = "frame",
  st = "style",
}
