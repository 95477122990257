import classNames from "classnames";
import { isEmpty } from "lodash";
import Link from "next/link";
import { FC, Fragment, useId, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import Avatar from "~/components/Avatar";
import Divider from "~/components/Divider";
import Drawer from "~/components/Drawer";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { useMegaMenu } from "~/data/mega-menu/useMegaMenu";
import { UserAtom } from "~/services/user";
import { transformNodesToMenu } from "~/utils/transform";
import { renderDrawerMenu } from "./DrawerMenu";
import styles from "./HeaderTwo.module.scss";
import { MenuTransformProps } from "./interfaces";
import Skeleton from "react-loading-skeleton";

interface MobileHeaderProps {
  open: boolean;
  onClose: () => void;
}

const MobileHeaderTwo: FC<MobileHeaderProps> = ({ open, onClose }) => {
  const id = useId();
  const [idReset, setIdReset] = useState(id);

  const currentUser = useRecoilValue(UserAtom.currentUser);

  const { nodes: megaMenu, loading } = useMegaMenu({
    identifiers: "menu-mobile",
  });

  const menu = useMemo(() => {
    if (loading) return Array.from({ length: 5 });
    return isEmpty(megaMenu) ? [] : transformNodesToMenu(megaMenu);
  }, [loading, megaMenu]) as MenuTransformProps[];

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        setIdReset((prev) => prev + 1);
      }}
      className={styles.mobile_header}
      titleClassName={classNames("items-center px-3", styles.drawer_title)}
      title={
        isEmpty(currentUser) ? (
          <div className="flex items-center gap-3">
            <Link href="/sign-in">
              <Text type="body-3">Sign in</Text>
            </Link>
            <Divider
              dividerClassName="h-[20px] w-[1px] border-t-0 border-l"
              dividerColor={Colors.GREY_50}
            />
            <Link href="/sign-up">
              <Text type="body-3">Sign up</Text>
            </Link>
          </div>
        ) : (
          <Avatar name={currentUser.firstname || "A"} />
        )
      }
      iconClassName={styles.drawer_icon}
    >
      <div
        className="flex flex-col gap-1 flex-1 p-3 overflow-auto"
        key={idReset}
        style={{ paddingBottom: 70 }}
      >
        {menu.map((m, index) => {
          return (
            <Fragment key={index}>
              {loading ? (
                <Skeleton height={30} />
              ) : (
                renderDrawerMenu(m, onClose)
              )}
            </Fragment>
          );
        })}
      </div>
    </Drawer>
  );
};

export default MobileHeaderTwo;
