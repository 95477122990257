import { getRecoil } from "recoil-nexus";
import { Cart, CartItem, PaymentMethod, ShippingMethod } from "../cart";
import { Order } from "../order";
import { Product, ProductVariant } from "../product";
import { UserAtom } from "../user";
import {
  FacebookPixelAddPaymentInfo,
  FacebookPixelAddToCart,
  FacebookPixelAddToWishList,
  FacebookPixelCategory,
  FacebookPixelInitiateCheckout,
  FacebookPixelPageView,
  FacebookPixelProduct,
  FacebookPixelPurchase,
  FacebookPixelSearch,
} from "./FacebookPixel";
import {
  GoogleAnalyticsAddToCartG4,
  GoogleAnalyticsPurchaseG4,
  GoogleAnalyticsSelectItemG4,
  GoogleAnalyticsSelectPaymentG4,
  GoogleAnalyticsSelectShippingG4,
  GoogleAnalyticsStartOrderG4,
  GoogleAnalyticsViewItemG4,
  GoogleAnalyticsViewLisItemG4,
} from "./GooglAnalyticsG4";
import {
  klaviyoTrack,
  klaviyoTrackAddToCart,
  klaviyoTrackViewProduct,
} from "./klaviyo";
import {
  TracadiRemoveItemCart,
  TracardiAddToCart,
  TracardiIdentification,
  TracardiOrderComplete,
  TracardiPageView,
  TracardiProductReviewed,
  TracardiPurchase,
  TracardiScreenView,
  TracardiSearch,
  TracardiSearchResult,
  TracardiSelectPayment,
  TracardiSelectShipping,
  TracardiSignIn,
  TracardiStartOrder,
  TracardiViewItem,
} from "./Tracardi";
import { ReviewItem } from "~/data/product/interfaces";
import {
  PintrkAddToCart,
  PintrkCheckout,
  PintrkProductView,
  PintrkSearch,
  PintrkViewCategory,
} from "./Pinterest";
import {
  TiktokTrackAddToCart,
  TitokTrackAddPaymentInfo,
  TitokTrackAddToWishlist,
  TitokTrackCompletePayment,
  TitokTrackInitiateCheckout,
  TitokTrackSearch,
  TitokTrackViewContent,
} from "./TiktokPixel";

export const trackingPageView = (url: string) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  FacebookPixelPageView();
  TracardiScreenView(url, "Page View");
  TracardiPageView(url, "Page View");

  // Tracking code
};

export const trackingOnProductClick = (variant?: Partial<ProductVariant>) => {
  const currentUser = getRecoil(UserAtom.currentUser);

  // Tracking code
};

export const trackingProductView = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  GoogleAnalyticsViewItemG4(payload);
  FacebookPixelProduct(payload);
  TitokTrackViewContent(payload);
  klaviyoTrackViewProduct(payload);
  TracardiViewItem(payload);
  PintrkProductView(payload);
  // Tracking code
};

/**
 * Triggers the tracking event when a product is added to the cart.
 *
 * @param {Object} payload - The payload containing the variant, product, and quantity information.
 * @param {Partial<ProductVariant>} payload.variant - The variant of the product.
 * @param {Partial<Product>} payload.product - The product information.
 * @param {number} payload.qty - The quantity of the product added to the cart.
 */
export const trackingAddToCartClick = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  klaviyoTrackAddToCart(payload);
  FacebookPixelAddToCart(payload);
  GoogleAnalyticsAddToCartG4(payload);
  TracardiAddToCart(payload);
  PintrkAddToCart(payload);
  TiktokTrackAddToCart(payload);

  // Tracking code
};

export const trackingBuyNowClick = (payload: {
  variant?: Partial<ProductVariant>;
  product?: Partial<Product>;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  // Tracking code
};

export const trackingPlaceOrderClick = () => {
  const currentUser = getRecoil(UserAtom.currentUser);

  // Tracking code
};

export const trackingQuantityChange = (payload: {
  variant?: Partial<ProductVariant>;
  quantity: number;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);

  // Tracking code
};

export const trackingShippingMethodChange = (payload: {
  cart: Cart;
  shippingMethod: ShippingMethod;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  GoogleAnalyticsSelectShippingG4(payload);
  TracardiSelectShipping(payload);

  // Tracking code
};

/**
 * Tracks the change of a payment method.
 *
 * @param {Object} payload - The payload containing the cart and payment method.
 * @param {Cart} payload.cart - The cart object.
 * @param {PaymentMethod} payload.paymentMethod - The payment method object.
 */
export const trackingPaymentMethodChange = (payload: {
  cart: Cart;
  paymentMethod: PaymentMethod;
}) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  GoogleAnalyticsSelectPaymentG4(payload);
  TracardiSelectPayment(payload);
  FacebookPixelAddPaymentInfo(payload);
  TitokTrackAddPaymentInfo(payload);
  // Tracking code
};

/**
 * Removes an item from the cart and tracks the event.
 *
 * @param {CartItem} cartItem - The item to be removed from the cart.
 * @return {void} This function does not return a value.
 */
export const trackingRemoveItemFromCart = (cartItem: CartItem) => {
  const currentUser = getRecoil(UserAtom.currentUser);
  TracadiRemoveItemCart(cartItem);

  // Tracking code
};
export const trackingPurchase = (order: Partial<Order>) => {
  FacebookPixelPurchase({ order });
  GoogleAnalyticsPurchaseG4({ order });
  TracardiOrderComplete({ orderId: order?.order_number });
  TracardiPurchase(order);
  PintrkCheckout(order as Order);
  TitokTrackCompletePayment(order);

  // Tracking code
};
export const trackingSearchPage = (payload: {
  keyword: string;
  data: Product[];
}) => {
  FacebookPixelSearch(payload);
  TracardiSearchResult(payload);
  TracardiSearch(payload.keyword, "Search");
  PintrkSearch(payload);
  TitokTrackSearch(payload);
  // Tracking code
};
export const trackingCategory = (payload: {
  keyword: string;
  data: Product[];
}) => {
  FacebookPixelCategory(payload);
  PintrkViewCategory(payload);

  // Tracking code
};

export const trackingBeginCheckout = (payload: { cart: Cart }) => {
  // FacebookPixelCategory(payload)
  // Tracking code
  GoogleAnalyticsStartOrderG4({ cart: payload.cart });
  FacebookPixelInitiateCheckout({ cartInfo: payload.cart });
  TracardiStartOrder({ cart: payload.cart });
  TitokTrackInitiateCheckout(payload);
};

export const trackingViewListItem = (payload: {
  products: Array<Partial<Product>>;
  listKey: string;
}) => {
  const { products, listKey } = payload;
  // FacebookPixelCategory(payload)
  // Tracking code

  GoogleAnalyticsViewLisItemG4({ products, listKey });
};

export const trackingSelectItem = (payload: {
  product: Partial<Product>;
  index: number;
  listKey: string;
}) => {
  const { product, listKey, index } = payload;
  // FacebookPixelCategory(payload)
  // Tracking code

  GoogleAnalyticsSelectItemG4({ product, listKey, index });
};

export const trackIdentification = (payload: {
  email: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  id?: string;
  passport?: string;
  password?: string;
}) => {
  TracardiIdentification(payload);
};
export const trackingProductReview = (payload: {
  reviewItem: Partial<ReviewItem>;
}) => {
  TracardiProductReviewed(payload.reviewItem);
};

export const trackingSignIn = (payload: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  date_of_birth: string;
  is_subscribed: boolean;
  [x: string]: any;
}) => {
  TracardiSignIn(payload);
};
export const trackingWishList = (payload: { product: Partial<Product> }) => {
  FacebookPixelAddToWishList(payload);
  TitokTrackAddToWishlist(payload);
};
