import { atom, selector } from "recoil";
import { localStorageEffect } from "../storage";
import { User } from "./user.type";

const currentUser = atom<Partial<User> | undefined>({
  key: "current-user",
  default: undefined,
  // effects: [localStorageEffect("user")],
});

const addressSelector = selector({
  key: "address-selector",
  get: ({ get }) => {
    const user = get(currentUser);
    const shipping_addresses = user?.shipping_address
    const defaultShippingAddress = shipping_addresses?.find((ship) => ship.default_shipping) ||
    shipping_addresses?.[0]

    return {
      ...user,
      defaultShippingAddress,
      // totalSelected,
    };
  },
});

export default { currentUser , addressSelector};
