import Head from "next/head";
import React from "react";
import { Product, ProductVariant } from "../product";
import { Cart, PaymentMethod } from "../cart";
import { Order } from "../order";
import Script from "next/script";

type Props = {};
type EventName =
  | "ViewContent"
  | "Search"
  | "AddToCart"
  | "AddPaymentInfo"
  | "AddToWishlist"
  | "CompletePayment"
  | "InitiateCheckout";

export function TitokTrackViewContent(payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  const data = {
    content_type: "product_group",
    quantity: payload.qty,
    description: payload.product.description,
    content_id: payload.variant?.product?.sku,
    currency:
      payload.variant?.product?.price_range?.minimum_price?.final_price
        ?.currency,
    value:
      payload.variant?.product?.price_range?.minimum_price?.final_price?.value,
  };
  TiktokTrackEvent("ViewContent", data);
}
export function TitokTrackSearch(payload: {
  keyword: string;
  data: Product[];
}) {
  const data = {
    query: payload.keyword,
  };
  TiktokTrackEvent("Search", data);
}
export function TiktokTrackAddToCart(payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  const data = {
    content_type: "product_group",
    description: payload.product.description,
    currency:
      payload.variant?.product?.price_range?.minimum_price?.final_price
        ?.currency,
    value:
      payload.variant?.product?.price_range?.minimum_price?.final_price?.value,
    contents: [
      {
        content_id: payload.variant?.product?.sku,
        content_name: payload.variant?.product?.name,
        quantity: payload.qty,
        price:
          payload.variant?.product?.price_range?.minimum_price?.final_price
            ?.value,
      },
    ],
  };
  TiktokTrackEvent("AddToCart", data);
}
export function TitokTrackAddPaymentInfo(payload: {
  cart: Cart;
  paymentMethod: PaymentMethod;
}) {
  const data = {};
  TiktokTrackEvent("AddPaymentInfo", data);
}
export function TitokTrackAddToWishlist(payload: {
  product: Partial<Product>;
}) {
  const data = {
    content_type: "product_group",
    content_id: payload.product.sku,
    currency: payload.product.price_range?.minimum_price?.final_price?.currency,
    value: payload.product.price_range?.minimum_price?.final_price?.value,
  };
  TiktokTrackEvent("AddToWishlist", data);
}
export function TitokTrackCompletePayment(order: Partial<Order>) {
  const data = {
    content_type: "product_group",
    value: order.prices?.grand_total?.value ?? 0,
    currency: order?.prices?.grand_total?.currency,
    description: "complete payment",
    contents: order.items?.map((i) => ({
      content_id: i.product?.sku,
      quantity: i.quantity,
      price:
        i.selectedVariant?.product?.price_range?.minimum_price?.final_price
          ?.value,
      currency:
        i.selectedVariant?.product?.price_range?.minimum_price?.final_price
          ?.currency,
      content_name: i.selectedVariant?.product?.name,
    })),
  };
  TiktokTrackEvent("CompletePayment", data);
}
export function TitokTrackInitiateCheckout(payload: { cart: Cart }) {
  const data = {
    content_type: "product_group",
    contents: payload.cart?.items?.map((i) => ({
      content_id: i.product?.sku,
      quantity: i.quantity,
      price: i.price,
      currency: i.currency,
      content_name: i.name,
    })),
    value: payload.cart?.prices?.grand_total?.value ?? 0,
    currency: payload.cart?.prices?.grand_total?.currency,
  };
  TiktokTrackEvent("InitiateCheckout", data);
}

export const TiktokTrackEvent = (eventName: EventName, data: any) => {
  if (typeof window === "undefined") return;
  if (!!window.ttq) {
    window.ttq?.track(eventName, data);
  }
};

const TiktokPixel = (props: Props) => {
  return (
    <Head>
      <script
        id="tiktok-analytics"
        dangerouslySetInnerHTML={{
          __html: `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

            ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
            ttq.page();
            }(window, document, 'ttq');`,
        }}
      />
    </Head>
  );
};

export default TiktokPixel;
