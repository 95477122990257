import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { debounce, get, isArray, isEmpty } from "lodash";
import { useRouter } from "next/router";
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import Container from "~/components/Container";
import Icon from "~/components/Icon";
import Input from "~/components/Input";
import Tag from "~/components/Tag";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { AppContext } from "~/contexts/app.context";
import { SEARCH_SUGGESTION } from "~/data/search/gql";
import { UseGetSearchSuggestions } from "~/data/search/interface";
import useClickOutside from "~/hooks/useClickOutside";
import { popularProductSelector } from "~/services/popular-product/popular-product.atom";
import { Product } from "~/services/product";
import { recentKeywordState } from "~/services/recent-search/recent-search.atom";
import styles from "./SearchFilter.module.scss";

interface SearchFilterProps {
  recentKeywords?: string[];
  trendingKeywords?: string[];
}

const SearchFilter: FC<SearchFilterProps> = (props) => {
  const { recentKeywords = [], trendingKeywords = [] } = props;
  const router = useRouter();
  const searchFilterRef = useRef(null);
  const { openSearch, setOpenSearch } = useContext(AppContext);
  const setRecoilRecentKeyword = useSetRecoilState(recentKeywordState);

  const [searchValue, setSearchValue] = useState("");
  const [debounceSearchValue, setDebounceSearchValue] = useState<string>("");

  const debounceSearchHandler = useCallback(
    debounce((searchValue: string) => {
      setDebounceSearchValue(searchValue);
      setRecoilRecentKeyword?.((prev) => {
        if (isEmpty(searchValue) || prev.includes(searchValue)) {
          return prev;
        }
        return [searchValue, ...prev];
      });
    }, 500),
    []
  );

  // Search Suggestions
  const { data: searchSuggestionResponse, loading: searchSuggestionLoading } =
    useQuery<UseGetSearchSuggestions>(SEARCH_SUGGESTION, {
      variables: {
        keyword: debounceSearchValue,
      },
      onError: ({ message }) => console.error(message, "SEARCH_SUGGESTION"),
      skip: isEmpty(debounceSearchValue),
    });

  const searchSuggestions = useMemo(() => {
    if (searchSuggestionLoading) {
      return Array.from({ length: 5 }, (_, index) => "");
    } else {
      return get(searchSuggestionResponse, "SearchSuggestion", []);
    }
  }, [searchSuggestionResponse, searchSuggestionLoading]);

  // Products

  const handleSearchProduct = (value: string) => {
    if (isEmpty(value)) return;
    router.push({
      pathname: "/search",
      query: {
        keyword: value,
      },
    });
    setOpenSearch(false);
  };

  const handleKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isEmpty(searchValue)) return;
    if (e.key === "Enter") {
      router.push({
        pathname: "/search",
        query: {
          keyword: searchValue,
        },
      });
      setOpenSearch(false);
    }
  };

  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSearchHandler(value);
  };

  useClickOutside(searchFilterRef, handleCloseSearch);

  return (
    <Container>
      <div
        className={classNames(styles.search_filter, "dropdown-animation", {
          "dropdown-animation-show": openSearch,
        })}
        ref={searchFilterRef}
      >
        <Input
          placeholder="Search your sneaker..."
          value={searchValue}
          onChange={(e) => handleChangeSearchValue(e.target.value)}
          onKeyDown={handleKeyDownSearch}
          onClearText={() => handleChangeSearchValue("")}
          suffix={
            searchValue && (
              <Icon
                name="search-normal-1"
                color={Colors.GREY_400}
                size={20}
                className="cursor-pointer"
                onClick={() => handleSearchProduct(searchValue)}
              />
            )
          }
        />

        {debounceSearchValue ? (
          // products.length === 0 ? (
          //   <Fragment>
          //     <div className={styles.item}>
          //       <Text type="title-2" weight={700}>
          //         Search result
          //       </Text>
          //       <div className="flex-1 flex items-center justify-center">
          //         <Text type="title-3">No product found. Please try another keyword</Text>
          //       </div>
          //     </div>
          //   </Fragment>
          // ) : (
          <Fragment>
            {!isEmpty(searchSuggestions) && (
              <div className={styles.item}>
                <Text type="title-2" weight={700}>
                  Search Suggestion
                </Text>
                <div className={styles.tags}>
                  {searchSuggestions.map((keyword, index) => (
                    <Tag
                      key={index}
                      name={keyword}
                      onClick={() => handleSearchProduct(keyword)}
                    />
                  ))}
                </div>
              </div>
            )}
            {/* <div className={styles.item}>
                <Text type="title-2" weight={700}>
                  Search Result
                </Text>

                <div className={styles.products} onClick={() =>setOpenSearch(false)}>
                  {products.map((item) => (
                    <ProductItem key={item.id} data={item} />
                  ))}
                </div>
              </div>
              <div onClick={() => handleSearchProduct()} className="cursor-pointer">
                <Text type="label-3" weight={500} className={styles.view_all}>
                  View All
                </Text>
              </div> */}
          </Fragment>
        ) : (
          // )
          <Fragment>
            {recentKeywords.length ? (
              <div className={styles.item}>
                <Text type="title-2" weight={700}>
                  Recent Search
                </Text>
                <div className={styles.tags}>
                  {recentKeywords?.map((keyword) => (
                    <Tag
                      key={keyword}
                      name={keyword}
                      onClick={() => handleSearchProduct(keyword)}
                      onDelete={() =>
                        setRecoilRecentKeyword?.((prev) =>
                          prev.filter((item) => item !== keyword)
                        )
                      }
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {/* {[].length ? (
              <div className={styles.item}>
                <Text type="title-2" weight={700}>
                  Trending Search
                </Text>
                <div className={styles.tags}>
                  {trendingKeywords.map((keyword) => (
                    <Tag
                      key={keyword}
                      name={keyword}
                      onClick={() => handleSearchProduct(keyword)}
                      onDelete={() => {}}
                    />
                  ))}
                </div>
              </div>
            ) : null} */}
            {/* <div className={classNames(styles.item, "!hidden lg:!flex")}>
              <Text type="title-2" weight={700}>
                Popular Product
              </Text>
              <div className={styles.products} onClick={() =>   setOpenSearch(false)}>
                {popularProducts?.map((item: Product , index:number) => (
                  <ProductItem key={index} data={item} />
                ))}
              </div>
            </div> */}
          </Fragment>
        )}
      </div>
    </Container>
  );
};

export default SearchFilter;
