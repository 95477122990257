import { get } from "lodash";
import { Product, ProductVariant } from "../product";

export const klaviyoTrack = (
  type: "Add to cart" | "Viewed Product",
  data: any
) => {
  const _learnq = window ? window["_learnq"] : undefined;
  if (
    typeof window !== "undefined" &&
    _learnq &&
    !!_learnq.isIdentified &&
    !!_learnq.isIdentified()
  ) {
    const _learnq = window._learnq || [];

    _learnq.push(["track", type, data]);
  }
};

export const klaviyoTrackAddToCart = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const { product, variant, qty } = payload;
  const categoryMaster = product?.configurable_options
    ?.filter((item: any) => item?.attribute_code === "category_master")
    ?.map((item: any) => item?.label);

  const data = {
    ProductName: get(product, "name", ""),
    ProductID: get(product, "id", ""),
    SKU: get(product, "sku", ""),
    Categories: categoryMaster,
    ImageURL: product.image,
    URL: window.location.href,
    Brand: categoryMaster,
    Price: variant.product?.price_range?.minimum_price?.final_price,
    Quantity: qty,
    Variant: variant.attributes,
    // "CompareAtPrice": 14.99
  };
  klaviyoTrack("Add to cart", data);
};
export const klaviyoTrackViewProduct = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const { product, variant, qty } = payload;
  const categoryMaster = product?.configurable_options
    ?.filter((item: any) => item?.attribute_code === "category_master")
    ?.map((item: any) => item?.label);

  const data = {
    ProductName: get(product, "name", ""),
    ProductID: get(product, "id", ""),
    SKU: get(product, "sku", ""),
    Categories: categoryMaster,
    ImageURL: product.image,
    URL: window.location.href,
    Brand: categoryMaster,
    Price: variant.product?.price_range?.minimum_price?.final_price,
    Quantity: qty,
    Variant: variant.attributes,
    // "CompareAtPrice": 14.99
  };
  klaviyoTrack("Viewed Product", data);
};
