import { FC } from "react";
import Colors from "~/constants/colors";
import Icon from "../Icon";
import Text, { TextProps } from "../Text";
import styles from "./Tag.module.scss";
import SkeletonByTimeout from "../Skeletons/SkeletonByTimeout";
import Skeleton from "react-loading-skeleton";

interface TagProps {
  name?: string;
  nameType?: TextProps["type"];
  nameColor?: string;
  onClick?: () => void;
  onDelete?: () => void;
  backgroundColor?: string;
}

const Tag: FC<TagProps> = ({
  name,
  nameType = "body-2",
  nameColor = Colors.GREY_800,
  onClick,
  onDelete,
  backgroundColor = Colors.GREY_50,
}) => {
  return (
    <div className={styles.tag} onClick={onClick} style={{ backgroundColor }}>
      {name ?
        <>
          <Text type={nameType} color={nameColor}>
            <span className="px-1">
              {name}
            </span>
          </Text>
          {onDelete && (
            <Icon
              name="close"
              color={Colors.GREY_800}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              className="cursor-pointer !text-[18px]"
            />
          )}
        </>
        :
        <SkeletonByTimeout>
          <Skeleton width={80} />
        </SkeletonByTimeout>
      }
    </div>
  );
};

export default Tag;
