import Script from "next/script";
import { useRecoilValue } from "recoil";
import { UserAtom } from "../user";
import { Cart } from "../cart";
import { Order } from "../order";
import { Product, ProductCategory, ProductVariant } from "../product";
const crypto = require("crypto");
const PintrkTracking = () => {
  const currentUser = useRecoilValue(UserAtom.currentUser);
  function hashEmail(email: string) {
    if (!email) return "";
    // Trim whitespace and convert to lowercase
    const normalizedEmail = email.trim().toLowerCase();

    // Create a SHA-256 hash
    const hash = crypto.createHash("sha256");
    hash.update(normalizedEmail);

    // Return the hexadecimal representation of the hash
    return hash.digest("hex");
  }
  return (
    <>
      <Script id="pintrk-analytics" strategy="afterInteractive">
        {`
           !function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
            n=window.pintrk;n.queue=[],n.version="3.0";var
            t=document.createElement("script");t.async=!0,t.src=e;var
            r=document.getElementsByTagName("script")[0];
            r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${
              process.env.NEXT_PUBLIC_PINTEREST_TRACKING_ID
            }', {em: '${currentUser?.email ?? ""}'});
            pintrk('page');
           
            `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src={`https://ct.pinterest.com/v3/?event=init&tid=2613947864527&pd[em]=${hashEmail(
            currentUser?.email as string
          )}&noscript=1`}
        />
      </noscript>
    </>
  );
};

export default PintrkTracking;

export const pintrk = (action: string, eventName: string, data: any) => {
  if (typeof window === "undefined") return;
  if (!!window.pintrk) return window.pintrk(action, eventName, data);
};
export const PintrkCheckout = (order: Order) => {
  pintrk("track", "checkout", {
    event_id: order?.cartId,
    value: order.prices?.grand_total?.value,
    order_quantity: 1,
    currency: order.prices?.grand_total?.currency,
    order_id: order.order_number,
    line_items: order.items?.map((pr) => ({
      product_name: pr.selectedVariant?.product?.name,
      product_id: pr.selectedVariant?.product?.sku,
      product_price:
        pr.selectedVariant?.product?.price_range?.minimum_price?.final_price
          ?.value,
      product_quantity: pr.quantity,
    })),
  });
};

export const PintrkAddToCart = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const { product, variant, qty } = payload;
  pintrk("track", "addtocart", {
    event_id: product.sku,
    value: variant?.product?.price_range?.minimum_price?.final_price?.value,
    order_quantity: qty,
    currency:
      variant?.product?.price_range?.minimum_price?.final_price?.currency,
    line_items: [
      {
        product_name: variant?.product?.name,
        product_id: variant?.product?.sku,
        product_price:
          variant?.product?.price_range?.minimum_price?.final_price?.value,
        product_quantity: qty,
        product_category: product?.categories?.[0]?.name,
      },
    ],
  });
};

export const PintrkProductView = (payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) => {
  const { product, variant, qty } = payload;
  const maxLevel: any = product?.categories?.reduce(
    (max: number = 0, c: ProductCategory) => {
      return c.level > max ? c.level : max;
    },
    0
  );
  const categories = product?.categories?.find(
    (c: ProductCategory) => c.level === maxLevel
  );

  pintrk("track", "pagevisit", {
    event_id: product.sku,
    line_items: [
      {
        product_name: variant?.product?.name,
        product_id: variant?.product?.sku,
        product_category: categories?.name,
      },
    ],
  });
};
export const PintrkSearch = (payload: { keyword: string; data: Product[] }) => {
  pintrk("track", "search", {
    event_id: payload.keyword,
    search_query: payload.keyword,
  });
};
export const PintrkViewCategory = (payload: {
  keyword: string;
  data: Product[];
}) => {
  const { keyword, data } = payload;
  pintrk("track", "viewcategory", {
    event_id: keyword,
    line_items: [
      {
        product_category: keyword,
      },
    ],
  });
};
