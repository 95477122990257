import { gql } from '@apollo/client';

export const QUERY_GET_AGGREGATIONS_BY_KEYWORD = gql`
    query GetAggregations($search: String!) {
        products(search: $search) {
            aggregations {
                count
                label
                attribute_code
                options {
                    count
                    label
                    value
                }
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
        }
    }
`;

export const QUERY_SEARCH_BY_KEYWORD_GQL = gql`
    query products(
        $pageSize: Int = 24
        $currentPage: Int = 1
        $filter: ProductAttributeFilterInput
        $sort: ProductAttributeSortInput
        $search: String
    ) {
        products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
            aggregations {
                count
                label
                attribute_code
                options {
                    count
                    label
                    value
                }
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
            total_count
            items {
                id
                name
                url_key
                sku
                thumbnail {
                    url
                }
            }
            page_info {
                page_size
                current_page
            }
        }
    }
`;

export const SEARCH_SUGGESTION = gql`
    query SearchSuggestion($keyword: String!) {
        SearchSuggestion(keyword: $keyword, limit: 5)
    }
`;
