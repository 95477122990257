import dynamic from "next/dynamic";
import { FC, Fragment, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Container from "~/components/Container";
import { useMegaMenu } from "~/data/mega-menu/useMegaMenu";
import { recentKeywordState } from "~/services/recent-search/recent-search.atom";
import { transformNodesToMenu } from "~/utils/transform";
import SearchFilter from "../SearchFilter";
import styles from "./Navigation.module.scss";
import { cloneDeep, isEmpty } from "lodash";
import { megaMenuAtom } from "~/services/megaMenu";
import { QUERY_GET_MEGA_MENU_GQL } from "~/data/mega-menu/gql";
import { useQuery } from "@apollo/client";
import { NodesResponse } from "~/data/home/interface";

const MenuItem = dynamic(() => import("~/components/Items/MenuItem"), {
  ssr: false,
});
export const buildTree = (nodes: NodesResponse[]) => {
  const tree = cloneDeep(nodes);
  const map = new Map();
  tree.forEach((node) => map.set(node.node_id, node));
  tree.forEach((node) => {
    const parent = map.get(node.parent_id);
    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(node);
    }
  });
  return tree.filter((node) => node.level === 0);
};
const Navigation: FC = () => {
  const recoilRecentKeywords = useRecoilValue(recentKeywordState);
  const { menu } = useRecoilValue(megaMenuAtom.megaMenu)
  const [recentKeywords, setRecentKeywords] = useState<string[]>();

  // const { data, loading, error } = useQuery(QUERY_GET_MEGA_MENU_GQL, {
  //   fetchPolicy: "network-only",
  //   variables: {
  //     identifiers: "main-menu",
  //   },
  //   context: { fetchOptions: { method: "GET" } },
  // });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRecentKeywords(recoilRecentKeywords)
    }
  }, [recoilRecentKeywords])


  return (
    <div className="w-full max-w-[1328px] mx-auto px-4 items-center gap-x-4 gap-y-1 flex-wrap hidden lg:flex">
      {menu?.map((mItem: any, index) => (
        <Fragment key={mItem.id}><MenuItem data={mItem} index={index} /></Fragment>
      ))}
    </div>

  );
};

export default Navigation;
