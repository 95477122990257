import { atom, selector } from "recoil";
import { strip } from "~/utils/number";
import { localStorageEffect } from "../storage";
import { Wishlist } from "./wishlist.type";
import { transformProductItem, transformProductItems } from "~/utils/transform";
import { ProductItem } from "~/data/home/interface";

const wishlist = atom<Wishlist>({
  key: "wishlist",
  default: {
    id: null,
    items_count: 0,
    sharing_code: "",
    updated_at: "",
  },
  // effects: [localStorageEffect("cart")],
});

const WishlistSelector = selector({
  key: "wishlist-selector",
  get: ({ get }) => {
    const currentWishList = get(wishlist);
    const wishlistItems = currentWishList.items_v2?.items?.map((wishItem) => ({
      ...wishItem,
      product: {
        ...transformProductItem(wishItem.product as any),
        id: wishItem.product?.id,
      },
    }));
    return {
      ...currentWishList,
      wishlistItems,
      // totalSelected,
    };
  },
});

export default { wishlist, WishlistSelector };
