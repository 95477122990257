import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";
import { Fragment, useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RecoilRoot, useRecoilSnapshot } from "recoil";
import RecoilNexus from "recoil-nexus";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AppLayout } from "~/components/Layout";
import MaketingLayout from "~/components/Layout/MaketingLayout";
import Colors from "~/constants/colors";
import { AppContext } from "~/contexts/app.context";
import { trackingPageView } from "~/services/tracking";
import "~/styles/globals.css";
import { useApollo } from "~/utils/apollo-client";
import { getTheme } from "~/utils/theme";

import(`~/theme/${process.env.NEXT_PUBLIC_THEME}/styles/globals.css`).then(
  (res) => res.default
);
const AppHead = dynamic(async () => {
  try {
    const component = await import(`~/theme/${getTheme()}/components/AppHead`);
    if (component) {
      return component.default;
    } else {
      return await import("~/theme/base/components/AppHead");
    }
  } catch (error) {
    console.error("Error importing Header:", error);
    throw error;
  }
});

// const AppHead = dynamic(
//   () => import(`~/theme/${process.env.NEXT_PUBLIC_THEME}/components/AppHead`)
// );

// function DebugObserver() {
//   const snapshot: any = useRecoilSnapshot();
//   useEffect(() => {
//     console.debug("The following atoms were modified:");
//     for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
//       console.debug(node.key, snapshot.getLoadable(node));
//     }
//   }, [snapshot]);

//   return null;
// }

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [openSearch, setOpenSearch] = useState(false);
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    const handleRouteChange = (route: any) => {
      trackingPageView(route);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <Fragment>
      <ApolloProvider client={apolloClient}>
        <AppHead />

        <GoogleOAuthProvider clientId="GOOGLE_CLIENT_ID">
          <RecoilRoot>
            {/* <DebugObserver /> */}

            <MaketingLayout />
            <AppLayout>
              <AppContext.Provider
                value={{
                  openSearch,
                  setOpenSearch,
                }}
              >
                <div className={getTheme()}>
                  <Component {...pageProps} />
                </div>
              </AppContext.Provider>
            </AppLayout>
            <RecoilNexus />
          </RecoilRoot>
        </GoogleOAuthProvider>
        <NextNProgress
          color={Colors.PRIMARY}
          height={3}
          showOnShallow={false}
        />
        <ToastContainer />
      </ApolloProvider>
    </Fragment>
  );
}
