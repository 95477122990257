export const calculateDiscountedPrice = (
  price: string | number = "0",
  discount?: string | number
) => {
  const convertToNumber = !isNaN(Number(price)) ? Number(price) : 0;
  const convertDiscountToNumber = !isNaN(Number(discount))
    ? Number(discount)
    : 0;
  return convertToNumber - convertDiscountToNumber;
};

//* Pedro Ladaria's solution, this to fix JS inaccuracy in calculating
export const strip = (num: number) => {
  return parseFloat(num.toPrecision(12));
};

export function extractCurrencySymbol(currencyCode:string = "USD") {
  try {
    const currencySymbols = {
        "USD": "$", // United States Dollar
        "EUR": "€", // Euro
        "GBP": "£", // British Pound Sterling
        // Add more currency codes and symbols as needed
    };

    // Lookup the currency symbol
    const symbol = currencySymbols[currencyCode.toUpperCase() as keyof typeof currencySymbols];
      return symbol ? symbol : "$";
  } catch (error:any) {
      return "Error: " + error.message;
  }
}

const formatNumber = (num: number | undefined, fixed = 0) => {
  if (num === 0 || !num) return '0';

  return num
      ?.toFixed(fixed)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export { formatNumber };