import { gql } from "@apollo/client";

export const QUERY_GET_ACTUAL_PRODUCT = gql`
  query products($skus: [String!]!, $pageSize: Int = 20) {
    products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
      items {
        id
        catalogRule {
          expire_date
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        reviews(pageSize: 1, currentPage: 1) {
          total_count
          summary
          summary_detail
        }
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              sku
            }
          }
        }
      }
    }
  }
`;
export const QUERY_BESTSELLERS_ON_MENU = gql`
  query bestSellerProducts($currentPage: Int = 1, $pageSize: Int = 10) {
    bestSellerProducts(
      pageSize: $pageSize
      currentPage: $currentPage
      sort: { name: DESC }
    ) {
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        name
        sku
        id
        url_key
        thumbnail {
          url
          label
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;
export const QUERY_POPULAR_ON_MENU = gql`
  query popularProducts($currentPage: Int = 1, $pageSize: Int = 10) {
    popularProducts(
      pageSize: $pageSize
      currentPage: $currentPage
      sort: { name: DESC }
    ) {
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        name
        sku
        id
        url_key
        __typename
        small_image {
          url
          label
        }
      }
    }
  }
`;
export const QUERY_TRENDING_ON_MENU = gql`
  query trendingProducts($currentPage: Int = 1, $pageSize: Int = 10) {
    trendingProducts(
      pageSize: $pageSize
      currentPage: $currentPage
      sort: { name: DESC }
    ) {
      total_count
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        name
        sku
        id
        url_key
        thumbnail {
          url
          label
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_BY_SLUG = gql`
  query categories($url_key: String!) {
    categories(filters: { url_key: { eq: $url_key } }) {
      total_count
      items {
        id
        name
        url_key
      }
    }
  }
`;
export const GET_PRODUCTS_BY_CATEGORY_ID = gql`
  query products(
    $pageSize: Int = 24
    $currentPage: Int = 24
    $filter: ProductAttributeFilterInput
  ) {
    products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
      items {
        name
        sku
        id
        url_key
        small_image {
          url
          label
        }
      }
      total_count
      page_info {
        page_size
        current_page
      }
    }
  }
`;
export const QUERY_GET_MEGA_MENU_GQL = gql`
  query megamenu($identifiers: String!) {
    getMenuByIdentifier(identifier: $identifiers) {
      nodes {
        type
        node_id
        menu_id
        level
        content
        url
        classes
        label
        label_color
        label_background
        parent_id
        position
        title
        target
      }
    }
  }
`;

export const QUERY_GET_PRODUCT_DETAIL_ON_MENU = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        id
        sku
        name
        url_key
        thumbnail {
          url
          label
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              sku
            }
          }
        }
      }
    }
  }
`;
