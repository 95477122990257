import { get } from "lodash";
import { Product, ProductCategory, ProductVariant } from "../product";
import {
  Address,
  Cart,
  CartItem,
  PaymentMethod,
  ShippingMethod,
} from "../cart";
import { Order } from "../order";
import { ReviewItem, Reviews } from "~/data/product/interfaces";
import { getTheme } from "~/utils/theme";

export const trackEvent = (eventName: string, data: any) => {
  if (!window.tracker) return;
  return window.tracker?.track(eventName, data, { fire: true });
};

export function TracardiAddToCart(payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  const { product, variant, qty } = payload;

  const categoryMaster = product?.configurable_options
    ?.filter((item: any) => item?.attribute_code === "category_master")
    ?.map((item: any) => item?.label);

  trackEvent("product-added-to-basket", {
    price: variant.product?.price_range?.minimum_price?.final_price?.value,
    quantity: qty,
    sku: variant?.product?.sku,
    id: variant?.product?.id,
    name: variant?.product?.name,
    url: {
      page: window?.location?.pathname,
      image: get(product, ["pictures", 0, "url"], ""),
    },
    brand: categoryMaster,
    "variant-selected": variant.attributes
      ?.map((item: any) => item?.label)
      ?.join("/"),
    category: categoryMaster,
  });
}

export function TracardiViewItem(payload: {
  variant: Partial<ProductVariant>;
  product: Partial<Product>;
  qty: number;
}) {
  const { product, variant, qty } = payload;

  const categoryMaster = product?.configurable_options?.find(
    (item: any) => item?.attribute_code === "category_master"
  );
  const item = {
    price: variant?.product?.price_range?.minimum_price?.final_price?.value,
    quantity: qty,
    sku: product?.sku,
    id: product?.id,
    name: variant?.product?.name,
    url: {
      page: window?.location?.pathname,
      image: product?.image?.url,
    },
    brand: getTheme(),
    "variant-selected": variant?.attributes
      ?.map((item: any) => item?.label)
      .join("/"),
    category: variant?.attributes?.find(
      (item: any) => item?.attribute_code === "category_master"
    )?.label,
  };
  trackEvent("product-details-page-view", item);
}

export function TracadiRemoveItemCart(cartItem: CartItem) {
  if (!cartItem) {
    return;
  }

  trackEvent("product-removed-from-basket", {
    price:
      cartItem.selectedVariant?.product?.price_range?.minimum_price
        ?.final_price || 0,
    quantity: cartItem?.quantity,
    sku: cartItem?.sku,
    id: cartItem?.id,
    name: cartItem?.name,
    url: {
      page: window.location.href,
    },
    brand: cartItem.brand,
    "variant-selected": cartItem.selectedVariant?.attributes,
    category: cartItem.product?.categories?.map((item: any) => item?.label),
  });
}

export function TracardiSelectShipping(payload: {
  cart: Cart;
  shippingMethod: ShippingMethod;
}) {
  const { cart, shippingMethod } = payload;
  if (!cart.cartId) {
    return;
  }

  const shipping_addresses: Address = get(
    cart,
    ["shipping_addresses", 0],
    {} as Address
  );

  const firstNameShipping = shipping_addresses?.firstname;
  const lastNameShipping = shipping_addresses?.lastname;

  trackEvent("delivery-info-entered", {
    receiver: firstNameShipping + lastNameShipping,
    delivery: {
      postcode: get(cart, ["shipping_addresses", 0, "postcode"], ""),
      county: shipping_addresses?.region_code,
      street: shipping_addresses?.street,
      country: shipping_addresses?.country_code,
      town: shipping_addresses?.city,
    },
    shipping_addresses: shipping_addresses,
    order_id: cart.cartId,
    method: shippingMethod.carrier_code,
  });
}

export function TracardiSelectPayment(payload: {
  cart: Cart;
  paymentMethod: PaymentMethod;
}) {
  const { cart, paymentMethod } = payload;
  trackEvent("payment-info-entered", {
    order_id: cart.cartId,
    method: paymentMethod?.title,
    credit_card: {},
  });
}

export function TracardiStartOrder(payload: { cart: Cart }) {
  const { cart } = payload;
  const categoryMaster = get(cart, ["items", "product", "variants"])?.find(
    (item: any) => {
      return item?.attribute_code === "category_master";
    }
  );
  const tax = get(cart, ["prices", "applied_taxes"], [])?.reduce(
    (total: number, item: any) => {
      return total + item?.amount?.value;
    },
    0
  );
  trackEvent("order-started", {
    id: cart.cartId,
    products: cart?.items?.map((item: any) => {
      const _variant: any = {};
      item?.configurable_option?.forEach((config: any) => {
        _variant[config.code] = config.label;
      });
      return {
        id: item?.id,
        name: item?.product?.name,
        sku: item?.product?.sku,
        category: categoryMaster?.join(" "),
        brand: categoryMaster?.join(" "),
        "variant-selected": _variant,
        price: get(
          item,
          "product.price_range.minimum_price.final_price.value",
          0
        ),
        quantity: item.quantity,
        url: {
          image: get(item, ["products", "thumbnail", "url"], ""),
          page: window.location.href,
        },
      };
    }),
    affiliation: "",
    income: {
      revenue: get(cart, ["prices", "subtotal_excluding_tax", "value"], 0),
      value: get(cart, ["prices", "grand_total", "value"], 0),
    },
    cost: {
      //   "other": 5.00,
      discount: get(cart, ["prices", "discounts"], 0),
      shipping: get(
        cart,
        "shipping_addresses[0].selected_shipping_method.amount.value",
        0
      ),
      tax: tax,
    },
  });
}
export function TracardiOrderComplete({ orderId }: any) {
  trackEvent("order-completed", {
    order: {
      id: orderId,
      status: "completed",
    },
  });
}

// export function TracardiSignUp({
//     firstname,
//     lastname,
//     email,
//   ...otherData
// }:{
//     firstname: string;
//   lastname: string;
//   email: string;
//   phone: string;
//   date_of_birth: string;
//   is_subscribed: boolean;
//   [x:string]:any
// }) {
//     trackEvent("sign-up", {
//         "lastname": lastname,
//         "firstname": firstname,
//         "email": email,
//         "login": email,
//         ...otherData
//         // "custom": {
//         //     "plan": "premium",
//         //     "source": "google",
//         //     "campaign": "spring_sale"
//         // }
//     }
// );
// }
export function TracardiSignIn({
  firstname,
  lastname,
  email,
  ...otherData
}: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  date_of_birth: string;
  is_subscribed: boolean;
  [x: string]: any;
}) {
  trackEvent("sign-in", {
    lastname: lastname,
    firstname: firstname,
    email: email,
    login: email,
    ...otherData,
  });
}
// export function TracardiBasketViewed(id:any) {
//     trackEvent("basket-viewed", {"id": id });
// }

export function TracardiSearchResult(payload: {
  keyword: string;
  data: Product[];
}) {
  const { keyword, data } = payload;
  if (data?.length === 0) {
    return;
  }

  trackEvent("product-search-result", {
    products: data?.map((item) => {
      return {
        id: item?.id,
        sku: item?.sku,
        name: item?.name,
        category: "",
        url: {
          image: get(item, ["pictures", 0, "url"], ""),
          page: window.location.href,
        },
        price: item?.price_range?.minimum_price?.final_price?.value,
      };
    }),
    query: keyword,
    filters: [
      {
        key: "keyword",
        value: keyword,
      },
    ],
    type: "product",
  });
}

export function TracardiSearch(query: any, category: any) {
  trackEvent("Search", {
    query: query,
    category: category,
  });
}

export function TracardiProductReviewed(review: Partial<ReviewItem>) {
  trackEvent("product-reviewed", {
    review: get(review, "text"),
    rate: get(review, "average_rating", 0),
    id: get(review, "nickname", 0),
  });
}

export function TracardiPageView(name: any, category: string) {
  // trackEvent("page-print", {
  //     "category": category
  //   })
  trackEvent("page-view", {
    name: name,
    category: category,
  });
}
export function TracardiScreenView(name: any, category: string) {
  // trackEvent("page-print", {
  //     "category": category
  //   })
  trackEvent("screen-view", {
    name: name,
    category: category,
  });
}

export function TracardiIdentification(payload: {
  email: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  id?: string;
  passport?: string;
  password?: string;
}) {
  if (!payload) {
    return;
  }

  trackEvent("identification", {
    email: payload.email,
    firstname: payload.firstname,
    lastname: payload.lastname,
    phone: payload.phone,
    id: payload.id,
    passport: payload.password,
    // credit_card: data.password,
    // coupon: 'DISCOUNT10',
    // badge: 'VIP',
    // twitter: '@johndoe',
    // whatsapp: '1234567890',
    // discord: 'johndoe#1234',
    // slack: 'johndoe',
    // telegram: '@johndoe',
    // wechat: 'johndoe123',
    // viber: '1234567890',
    // signal: '1234567890'
  });
}

// export function TracardiCheckoutStart(data:any) {
//     trackEvent("checkout-started", {
//         "coupon": data?.coupon,
//         "value":  data?.value,
//         "id":  data?.id,
//         "order_id": data?.order_id,
//         "currency": data.currency
//       });
// }

// export function TracardiCheckoutStatus({status, id}:any) {
//     trackEvent("checkout-status", { "status": status,
//     "id": id});
// }
// export function TracardiCheckoutComplete(id:any) {
//     trackEvent("checkout-completed", {"id": id });
// }
export function TracardiPurchase(order: Partial<Order>) {
  trackEvent("purchase", {
    transaction_id: order?.order_number,
    currency: order?.prices?.grand_total?.currency,
    value: order?.prices?.grand_total?.value,
    affiliation: "",
    shipping: order?.selected_shipping_method?.carrier_code ?? "",
    tax: order?.prices?.applied_taxes ?? "",
    coupon: order.applied_coupons,
    discount:
      order?.prices?.discounts?.reduce(
        (total = 0, item) => item.amount.value + total,
        0
      ) || 0, // (()),
    items: order?.items?.map((item) => {
      return {
        item_id: item?.product?.sku,
        item_name: item?.selectedVariant?.product?.name,
        currency:
          item?.selectedVariant?.product?.price_range?.minimum_price
            ?.final_price?.currency,
        affiliation: "",
        item_variant: item.selectedVariant?.attributes
          ?.map((i) => i.label)
          ?.join("/"),
        price:
          item?.selectedVariant?.product?.price_range?.minimum_price
            ?.final_price?.value || 0,
        quantity: item?.quantity ?? "",
      };
    }),
  });
}
