/* eslint-disable @next/next/no-img-element */
import { useQuery } from "@apollo/client";
import { FC } from "react";
import Container from "~/components/Container";
import { styles as TextStyes } from "~/components/Text";
import { Identifiers } from "~/constants/enum";
import { QUERY_GET_CMS_BLOCKS } from "~/data/home";
import { replaceClassNames } from "~/utils/converters";
import styles from "./Footer.module.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const {data ,  loading , error} =  useQuery(QUERY_GET_CMS_BLOCKS ,{
    variables: {
      identifiers:[Identifiers.Footer , Identifiers.CopyRight]
    },
    context: { fetchOptions: { method: "GET" } }
  })
  const complieStyle = {
    Footer:styles,
    Text:TextStyes
  }
  const copyRightArea =  data?.cmsBlocks?.items?.find((cms:any) => cms.identifier === Identifiers.CopyRight)?.content
  const footerArea =  data?.cmsBlocks?.items?.find((cms:any) => cms.identifier === Identifiers.Footer)?.content
  
  return (
    <Container rootClassName={styles.footer_wrapper} className={styles.footer}>
      <div dangerouslySetInnerHTML={{
        __html: replaceClassNames(footerArea , complieStyle)
      }} />
      <div dangerouslySetInnerHTML={{
        __html: replaceClassNames(copyRightArea , complieStyle)
      }} />
    </Container>
  );
};

export default Footer;
