import { useMemo } from "react";
import merge from "deepmerge";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  HttpOptions,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concatPagination } from "@apollo/client/utilities";

let apolloClient: any;

const authLink = setContext((_, { headers, isPreventToken = false }) => {
  let token = null;
  let Store = "default";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("token");
    Store = localStorage.getItem("store") || "default";
  }

  const authorization = token && !isPreventToken ? `Bearer ${token}` : null;

  return {
    headers: Object.assign(
      {},
      {
        ...headers,
        Store,
      },
      authorization && { authorization }
    ),
  };
});

const methodLink = new ApolloLink((operation, forward) => {
  if (
    operation.query.definitions.some(
      (def: any) =>
        def.kind === "OperationDefinition" && def.operation === "mutation"
    )
  ) {
    operation.setContext({
      fetchOptions: {
        method: "POST",
      },
    });
  } else {
    operation.setContext({
      fetchOptions: {
        method: "GET",
      },
    });
  }
  return forward(operation);
});

interface ApolloClientOptions {
  httpLinkProps?: HttpOptions;
}

function createApolloClient({ httpLinkProps }: ApolloClientOptions = {}) {
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,

    ...httpLinkProps,
    fetch: (uri, options) => {
      return fetch(uri, options);
    },
  });

  return new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

interface ApolloClientInitial extends ApolloClientOptions {
  initialState?: any;
}

type ApolloClientType = ReturnType<typeof createApolloClient>;
export function initializeApollo({
  initialState = null,
  ...rest
}: ApolloClientInitial = {}): ApolloClientType {
  const _apolloClient = apolloClient ?? createApolloClient({ ...rest });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((d: any) =>
          sourceArray.every((s: any) => JSON.stringify(d) !== JSON.stringify(s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(
    () => initializeApollo({ initialState }),
    [initialState]
  );

  return store;
}
